import {
    handleQueryResolve
} from '../utils';

export default function () {
    return this.query(`
        SELECT
            GenericName,
            Strength,
            Mfr,
            SUM(
                CASE WHEN QtyNon = -9999999 THEN 0 ELSE QtyNon END
            ) AS Stock
        FROM [Fillware].[dbo].[DrugPack]
            INNER JOIN [Fillware].[dbo].[DrugRoot] ON[Fillware].[dbo].[DrugRoot].DIN = [Fillware].[dbo].[DrugPack].DINx
        WHERE
            GenericName LIKE('%PAPAVERINE HCL%')
            OR GenericName LIKE('%FLUOROURACIL%')
            OR GenericName LIKE('%SULFACETAMIDE SODIUM%')
            OR GenericName LIKE('%GUAIA CARB/DIPHENYL./CAMP%')
            OR GenericName LIKE('%CAMPHOR/EUCALIPT/GUAIACOL%')
            OR GenericName LIKE('%AMPHOR/EUCALIPT/GUAIACOL%')
            OR GenericName LIKE('%OXYCODONE HCL%')
            OR GenericName LIKE('%SODIUM CHLORIDE%')
            OR GenericName LIKE('%DIMENHYDRINATE%')
            OR GenericName LIKE('%MORPHINE SULFATE%')
            OR GenericName LIKE('%ATROPINE SULFATE%')
            OR GenericName LIKE('%ATROPINE SULPHATE%')
            OR GenericName LIKE('%DICYCLOMINE HCL%')
            OR GenericName LIKE('%SODIUM BICARBONATE%')
            OR GenericName LIKE('%ALCOHOL ANHYDROUS%')
            OR GenericName LIKE('%DIAZEPAM%')
            OR GenericName LIKE('%FUROSEMIDE%')
            OR GenericName LIKE('%GRAMICIDIN/POLYMIXIN B%')
            OR GenericName LIKE('%FLUOROMETHOLONE%')
            OR GenericName LIKE('%VITAMIN B12%')
            OR GenericName LIKE('%FURASEMIDE%')
            OR GenericName LIKE('%CODEINE PHOSPHATE%')
            OR GenericName LIKE('%PROMETHAZINE HCL%')
            OR GenericName LIKE('%COMBINATION OF VITAMINS%')
            OR GenericName LIKE('%DIPHENHYDRAMINE HCL%')
            OR GenericName LIKE('%ZINC SULFATE%')
            OR GenericName LIKE('%DEXAMETHASONE SOD PHOSPHA%')
            OR GenericName LIKE('%GRAMICIDIN/POLYMYXIN B%')
            OR GenericName LIKE('%MEPERIDINE HCL%')
            OR GenericName LIKE('%HYDROXYZINE HCL%')
            OR GenericName LIKE('%CHLORPROMAZINE HCL%')
            OR GenericName LIKE('%PHENYTOIN SODIUM%')
            OR GenericName LIKE('%VITAMIN C (SODIUM ASCORB)%')
            OR GenericName LIKE('%PROCHLORPERAZINE%')
            OR GenericName LIKE('%VITAMIN K1%')
            OR GenericName LIKE('%GRAMICIDIN/NEOMYCIN/POLYM%')
            OR GenericName LIKE('%HALOPERIDOL%')
            OR GenericName LIKE('%THIAMINE HCL%')
            OR GenericName LIKE('%METHYLENE BLUE%')
            OR GenericName LIKE('%NITROGLYCERIN%')
            OR GenericName LIKE('%POLYMYXIN B/LIDOCAINE HCL%')
            OR GenericName LIKE('%NEOSTIGMINE METHYLSULFATE%')
            OR GenericName LIKE('%HYPROMELLOSE%')
            OR GenericName LIKE('%EPHEDRINE SULFATE%')
            OR GenericName LIKE('%NOREPINEPHRINE BITARTRATE%')
            OR GenericName LIKE('%ISOPROTERENOL HCL%')
            OR GenericName LIKE('%OPIUM/BELLADONNA%')
            OR GenericName LIKE('%NALBUPHINE HCL%')
            OR GenericName LIKE('%PREDNISOLONE ACETATE%')
            OR GenericName LIKE('%POLYMYXIN B/BACITRACIN%')
            OR GenericName LIKE('%PHENYLEPHRINE HCL%')
            OR GenericName LIKE('%COCAINE HCL%')
            OR GenericName LIKE('%CHLORAMPHENICOL%')
            OR GenericName LIKE('%CHLORAMPHENICOL/HYDROCORT%')
            OR GenericName LIKE('%PYRID. HCL/THIAM.HCL/VB12%')
            OR GenericName LIKE('%HYDROCORTISONE ACETATE%')
            OR GenericName LIKE('%ZOPICLONE%')
            OR GenericName LIKE('%PHENOL%')
            OR GenericName LIKE('%PHENYLEPHRINE HCL/TROPICA%')
            OR GenericName LIKE('%VITAMIN C%')
            OR GenericName LIKE('%GLYCOPYRROLATE%')
            OR GenericName LIKE('%DIGOXIN%')
            OR GenericName LIKE('%TESTOSTERONE/ESTRADIOL%')
            OR GenericName LIKE('%CALCIUM SENNOSIDES A & B%')
            OR GenericName LIKE('%CHROM/COP/IOD/MAG/SEL/ZIN%')
            OR GenericName LIKE('%ELECTROLY.IN COMB W/OTHER%')
            OR GenericName LIKE('%CHROM./COPP./MANGAN./ZINC%')
            OR GenericName LIKE('%CHROMIUM/COPPER SULFATE%')
            OR GenericName LIKE('%HALOPERIDOL DECANOATE%')
            OR GenericName LIKE('%HYDROMORPHONE HCL%')
            OR GenericName LIKE('%NALOXONE HCL%')
            OR GenericName LIKE('%SALBUTAMOL SULFATE%')
            OR GenericName LIKE('%TIMOLOL MALEATE%')
            OR GenericName LIKE('%VERAPAMIL HCL%')
            OR GenericName LIKE('%DROPERIDOL%')
            OR GenericName LIKE('%PANCURONIUM BROMIDE%')
            OR GenericName LIKE('%LOXAPINE HCL%')
            OR GenericName LIKE('%PROCAINAMIDE HCL%')
            OR GenericName LIKE('%BRETYLIUM TOSYLATE%')
            OR GenericName LIKE('%METOCLOPRAMIDE HCL%')
            OR GenericName LIKE('%IRON DEXTRAN COMPLEX%')
            OR GenericName LIKE('%PROPRANOLOL HCL%')
            OR GenericName LIKE('%GENTAMICIN SULFATE%')
            OR GenericName LIKE('%TRIAMCINOLONE ACETONIDE%')
            OR GenericName LIKE('%SCOPOLAMINE N-BUTYLBROMID%')
            OR GenericName LIKE('%HC/NEOMYCIN/POLYMYXIN B%')
            OR GenericName LIKE('%NAPROXEN%')
            OR GenericName LIKE('%CLINDAMYCIN PHOSPHATE%')
            OR GenericName LIKE('%PROTAMINE SULFATE%')
            OR GenericName LIKE('%LABETALOL HCL%')
            OR GenericName LIKE('%ATENOLOL%')
            OR GenericName LIKE('%INDOMETHACIN%')
            OR GenericName LIKE('%CLONAZEPAM%')
            OR GenericName LIKE('%METFORMIN HCL%')
            OR GenericName LIKE('%NITRAZEPAM%')
            OR GenericName LIKE('%SOTALOL HCL%')
            OR GenericName LIKE('%BETAXOLOL HCL%')
            OR GenericName LIKE('%ATRACURIUM BESYLATE%')
            OR GenericName LIKE('%IDOXURIDINE%')
            OR GenericName LIKE('%MINOCYCLINE HCL%')
            OR GenericName LIKE('%FERROUS FUMARATE%')
            OR GenericName LIKE('%BETAMETHASONE NA PHOS/ACE%')
            OR GenericName LIKE('%POLYMYX B SUL/TRIMET.SUL%')
            OR GenericName LIKE('%VALPROIC ACID%')
            OR GenericName LIKE('%KETOROLAC TROMETHAMINE%')
            OR GenericName LIKE('%MIDAZOLAM%')
            OR GenericName LIKE('%FENTANYL CITRATE%')
            OR GenericName LIKE('%DIHYDROERGOTAMINE MESYLAT%')
            OR GenericName LIKE('%TOBRAMYCIN SULFATE%')
            OR GenericName LIKE('%DICLOFENAC SODIUM%')
            OR GenericName LIKE('%LEVOBUNOLOL HCL%')
            OR GenericName LIKE('%TOBRAMYCIN%')
            OR GenericName LIKE('%DOBUTAMINE HCL%')
            OR GenericName LIKE('%AMIODARONE HCL%')
            OR GenericName LIKE('%BACITRACIN/POLYM/HC/NEOMY%')
            OR GenericName LIKE('%DIBUCAINE/ESCULIN/FRAM/HC%')
            OR GenericName LIKE('%DIBUCAIN/ESCULIN/FRAMY/HC%')
            OR GenericName LIKE('%MULTIVITAMIN PREPARATIONS%')
            OR GenericName LIKE('%GENTAMYCIN SULFATE%')
            OR GenericName LIKE('%PRAMOXINE HCL/HC/ZINC SUL%')
            OR GenericName LIKE('%HC/ZINC SULFATE%')
            OR GenericName LIKE('%CYCLOSPORINE%')
            OR GenericName LIKE('%NABUMETONE%')
            OR GenericName LIKE('%AMIKACIN SULFATE%')
            OR GenericName LIKE('%ACETYLCYSTEINE%')
            OR GenericName LIKE('%RANITIDINE HCL%')
            OR GenericName LIKE('%DIMETHYL SULFOXIDE%')
            OR GenericName LIKE('%LORAZEPAM%')
            OR GenericName LIKE('%DILTIAZEM HCL%')
            OR GenericName LIKE('%FLUOXETINE HCL%')
            OR GenericName LIKE('%ORPHENADRINE CITRATE%')
            OR GenericName LIKE('%TICLOPIDINE HCL%')
            OR GenericName LIKE('%PHENTOLAMINE MESYLATE%')
            OR GenericName LIKE('%SUFENTANIL CITRATE%')
            OR GenericName LIKE('%MILRINONE LACTATE%')
            OR GenericName LIKE('%BETAMETHASONE/GENTAMICIN%')
            OR GenericName LIKE('%SERTRALINE HCL%')
            OR GenericName LIKE('%METHYLPREDNISOLONE ACETAT%')
            OR GenericName LIKE('%GOLD SODIUM THIOMALATE%')
            OR GenericName LIKE('%TESTOSTERONE CYPIONATE%')
            OR GenericName LIKE('%TRANEXAMIC ACID%')
            OR GenericName LIKE('%KETAMINE HCL%')
            OR GenericName LIKE('%ESTRADIOL HEMIHYDRATE%')
            OR GenericName LIKE('%FLUVOXAMINE MALEATE%')
            OR GenericName LIKE('%LOVASTATIN%')
            OR GenericName LIKE('%OFLOXACIN%')
            OR GenericName LIKE('%BISOPROLOL FUMARATE%')
            OR GenericName LIKE('%ZINC SULFATE/HYDROCORTISO%')
            OR GenericName LIKE('%SIMVASTATIN%')
            OR GenericName LIKE('%PRAVASTATIN SODIUM%')
            OR GenericName LIKE('%METOPROLOL TARTRATE%')
            OR GenericName LIKE('%DEXAMETHAS/FRAMYCET/GRAMI%')
            OR GenericName LIKE('%VASOPRESSIN%')
            OR GenericName LIKE('%GLYBURIDE%')
            OR GenericName LIKE('%CITALOPRAM HBR%')
            OR GenericName LIKE('%ALFENTANIL HCL%')
            OR GenericName LIKE('%FRAMOXINE HCL/ZINC SULFAT%')
            OR GenericName LIKE('%PRAMOXINE HCL/ZINC SULFAT%')
            OR GenericName LIKE('%FLUCONAZOLE%')
            OR GenericName LIKE('%TRIFLURIDINE%')
            OR GenericName LIKE('%CIPROFLOXACIN HCL%')
            OR GenericName LIKE('%FLUMAZENIL%')
            OR GenericName LIKE('%MIRTAZEPINE%')
            OR GenericName LIKE('%GLICLAZIDE%')
            OR GenericName LIKE('%PAROXETINE HCL%')
            OR GenericName LIKE('%CIPROFLOXACIN%')
            OR GenericName LIKE('%LOPERAMIDE HCL%')
            OR GenericName LIKE('%ACEBUTOLOL HCL%')
            OR GenericName LIKE('%TOPIRAMATE%')
            OR GenericName LIKE('%ANAGRELIDE HCL%')
            OR GenericName LIKE('%CALCITONIN (SALMON SYNTH)%')
            OR GenericName LIKE('%DICLOFENAC POTASSIUM%')
            OR GenericName LIKE('%PINDOLOL%')
            OR GenericName LIKE('%CARBAMAZEPINE%')
            OR GenericName LIKE('%TERBINAFINE HCL%')
            OR GenericName LIKE('%SUMATRIPTAN SUCCINATE%')
            OR GenericName LIKE('%PAMIDRONATE DISODIUM%')
            OR GenericName LIKE('%AZITHROMYCIN%')
            OR GenericName LIKE('%CLARITHROMYCIN%')
            OR GenericName LIKE('%MIRTAZAPINE%')
            OR GenericName LIKE('%GLIMEPIRIDE%')
            OR GenericName LIKE('%ONDANSETRON HCL%')
            OR GenericName LIKE('%BUPROPION HCL%')
            OR GenericName LIKE('%BICALUTAMIDE%')
            OR GenericName LIKE('%FAMCICLOVIR%')
            OR GenericName LIKE('%RISPERIDONE%')
            OR GenericName LIKE('%FELODIPINE%')
            OR GenericName LIKE('%LEFLUNOMIDE%')
            OR GenericName LIKE('%AMLODIPINE BESYLATE%')
            OR GenericName LIKE('%FOLIC ACID%')
            OR GenericName LIKE('%FENOFIBRATE%')
            OR GenericName LIKE('%ALENDRONATE SODIUM%')
            OR GenericName LIKE('%LISINOPRIL%')
            OR GenericName LIKE('%RAMIPRIL%')
            OR GenericName LIKE('%CEFTRIAXONE SODIUM%')
            OR GenericName LIKE('%TAMSULOSIN HCL%')
            OR GenericName LIKE('%OMEPRAZOLE%')
            OR GenericName LIKE('%PIOGLITAZONE HCL%')
            OR GenericName LIKE('%LEVOFLOXACIN%')
            OR GenericName LIKE('%PIPERACILLIN/TAZOBACTAM%')
            OR GenericName LIKE('%ENALAPRIL MALEATE%')
            OR GenericName LIKE('%PANTOPRAZOLE SODIUM%')
            OR GenericName LIKE('%GEMCITABINE HCL%')
            OR GenericName LIKE('%CEFPROZIL%')
            OR GenericName LIKE('%LISINOPRIL/HCTZ%')
            OR GenericName LIKE('%HEPARIN SODIUM%')
            OR GenericName LIKE('%PHENOBARBITAL SODIUM%')
            OR GenericName LIKE('%ALFUZOSIN HCL%')
            OR GenericName LIKE('%GRANISETRON HCL%')
            OR GenericName LIKE('%IRINOTECAN HCL%')
            OR GenericName LIKE('%BRIMONIDINE TARTRATE%')
            OR GenericName LIKE('%OXYCODO.HCL/ACETAMINOPHEN%')
            OR GenericName LIKE('%CEFAZOLIN SODIUM%')
            OR GenericName LIKE('%VENLAFAXINE HCL%')
            OR GenericName LIKE('%OLANZAPINE%')
            OR GenericName LIKE('%LOSARTAN POTASSIUM%')
            OR GenericName LIKE('%LOSARTAN POTASSIUM/HCTZ%')
            OR GenericName LIKE('%MYCOPHENOLATE MOFETIL%')
            OR GenericName LIKE('%QUETIAPINE FUMARATE%')
            OR GenericName LIKE('%RABEPRAZOLE SODIUM%')
            OR GenericName LIKE('%PRAMIPEXOLE DI-HCL MONOHY%')
            OR GenericName LIKE('%DORZOLAMIDE HCL%')
            OR GenericName LIKE('%ROCURONIUM BROMIDE%')
            OR GenericName LIKE('%SILDENAFIL CITRATE%')
            OR GenericName LIKE('%PACLITAXEL%')
            OR GenericName LIKE('%METHYLPHENIDATE HCL%')
            OR GenericName LIKE('%CELECOXIB%')
            OR GenericName LIKE('%MEDROXYPROGESTERONE ACET%')
            OR GenericName LIKE('%NARATRIPTAN HCL%')
            OR GenericName LIKE('%FINASTERIDE%')
            OR GenericName LIKE('%RIVASTIGMINE H-TARTRATE%')
            OR GenericName LIKE('%ATORVASTATIN CALCIUM%')
            OR GenericName LIKE('%SOMATROPIN%')
            OR GenericName LIKE('%TRANDOLAPRIL%')
            OR GenericName LIKE('%CANDESARTAN CILEXETIL%')
            OR GenericName LIKE('%FENTANYL%')
            OR GenericName LIKE('%CANDESARTAN CILEXET./HCTZ%')
            OR GenericName LIKE('%IRBESARTAN%')
            OR GenericName LIKE('%MONTELUKAST SODIUM%')
            OR GenericName LIKE('%DONEPEZIL HCL%')
            OR GenericName LIKE('%FLUPENTIXOL DECANOATE%')
            OR GenericName LIKE('%TOPOTECAN HCL%')
            OR GenericName LIKE('%IRBESARTAN/HCTZ%')
            OR GenericName LIKE('%ANASTROZOLE%')
            OR GenericName LIKE('%ROSUVASTATIN CALCIUM%')
            OR GenericName LIKE('%DORZOLAMIDE HCL/TIMOLOL%')
            OR GenericName LIKE('%MEMANTINE HCL%')
            OR GenericName LIKE('%LETROZOLE%')
            OR GenericName LIKE('%VALACYCLOVIR HCL%')
            OR GenericName LIKE('%RIZATRIPTAN BENZOATE%')
            OR GenericName LIKE('%VALSARTAN/HCTZ%')
            OR GenericName LIKE('%VALSARTAN%')
            OR GenericName LIKE('%REPAGLINIDE%')
            OR GenericName LIKE('%CILASTATIN/IMIPENEM%')
            OR GenericName LIKE('%OLOPATADINE HCL%')
            OR GenericName LIKE('%CLOPIDOGREL BISULFATE%')
            OR GenericName LIKE('%ZOLMITRIPTAN%')
            OR GenericName LIKE('%ESCITALOPRAM%')
            OR GenericName LIKE('%CISPLATIN%')
            OR GenericName LIKE('%LATANOPROST%')
            OR GenericName LIKE('%DOXORUBICIN HCL%')
            OR GenericName LIKE('%TELMISARTAN%')
            OR GenericName LIKE('%MEROPENEM TRIHYDRATE%')
            OR GenericName LIKE('%ENTACAPONE%')
            OR GenericName LIKE('%ETOPOSIDE%')
            OR GenericName LIKE('%VORICONAZOLE%')
            OR GenericName LIKE('%MOXIFLOXACIN HCL%')
            OR GenericName LIKE('%LANSOPRAZOLE%')
            OR GenericName LIKE('%BOSENTAN MONOHYDRATE%')
            OR GenericName LIKE('%ATOMOXETINE HCL%')
            OR GenericName LIKE('%PREGABALIN%')
            OR GenericName LIKE('%TELMISARTAN/HCTZ%')
            OR GenericName LIKE('%VANCOMYCIN HCL%')
            OR GenericName LIKE('%LATANOPROST/TIMOLOL%')
            OR GenericName LIKE('%METHOTREXATE SODIUM%')
            OR GenericName LIKE('%SOLIFENACIN SUCCINATE%')
            OR GenericName LIKE('%FLUVASTATIN SODIUM%')
            OR GenericName LIKE('%ZOLEDRONIC ACID%')
            OR GenericName LIKE('%ALMOTRIPTAN MALEATE%')
            OR GenericName LIKE('%TOLTERODINE TARTRATE%')
            OR GenericName LIKE('%TRAVOPROST%')
            OR GenericName LIKE('%TRAVOPROST/TIMOLOL%')
            OR GenericName LIKE('%OLANZAPINE TARTRATE%')
            OR GenericName LIKE('%EZETIMIBE%')
            OR GenericName LIKE('%TACROLIMUS%')
            OR GenericName LIKE('%CAPECITABINE%')
            OR GenericName LIKE('%LINEZOLID%')
            OR GenericName LIKE('%DUTASTERIDE%')
            OR GenericName LIKE('%RIVASTIGMINE%')
            OR GenericName LIKE('%BIMATOPROST%')
            OR GenericName LIKE('%ALENDRONATE SODIUM/VIT D3%')
            OR GenericName LIKE('%OXALIPLATIN%')
            OR GenericName LIKE('%DOCETAXEL%')
            OR GenericName LIKE('%DULOXETINE HCL%')
            OR GenericName LIKE('%NORETHINDRONE%')
            OR GenericName LIKE('%OLMESARTAN MEDOXOMIL%')
            OR GenericName LIKE('%MOMETASONE FUROATE%')
            OR GenericName LIKE('%AMPHETAMINE MIXED SALTS%')
            OR GenericName LIKE('%ESOMEPRAZOLE%')
            OR GenericName LIKE('%LEVETIRACETAM%')
            OR GenericName LIKE('%ETANERCEPT%')
            OR GenericName LIKE('%DEFERASIROX%')
            OR GenericName LIKE('%PERINDOPRIL ERBUMINE%')
            OR GenericName LIKE('%PERINDOPRIL/INDAPAMIDE%')
            OR GenericName LIKE('%ARIPIPRAZOLE%')
            OR GenericName LIKE('%LACOSAMIDEOR%')
        GROUP BY 
            GenericName,
            Strength,
            Mfr
        ORDER BY STOCK DESC
    `).then(handleQueryResolve);
    // .then((records) => {
    //   console.log(records);
    //   return records.map(row => buildMapToField(row));
    // });
}